import { RefariDTOTypes } from '@refari-frontend/types'
import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from 'src/utils/API'
import { ApiRoutes } from 'src/utils/Urls'

export type PromptTemplate =
  RefariDTOTypes['/dashboard/ai-retouch/prompt-templates/']['get']['responses']['200']['content']['application/json'][0]

export type ChatMessage = {
  message: string
  role: 'user' | 'assistant'
}

export class AIStore {
  @observable isLoading = false
  @observable promptsTemplates: PromptTemplate[] = []
  @observable threadId = ''
  @observable chatHistory: ChatMessage[] = []

  @action
  getPromptTemplates = async (): Promise<void> => {
    try {
      if (this.isLoading || this.promptsTemplates.length > 0) return
      this.isLoading = true
      const response = await API.getData(
        ApiRoutes.dashboard.ai.getPromptTemplates
      )
      runInAction(() => {
        this.promptsTemplates = response.data
        this.isLoading = false
      })
    } catch (error) {
      console.log(error)
    }
  }

  getAudioTranscript = async (audioBlob: Blob): Promise<string | null> => {
    try {
      const formData = new FormData()
      formData.append('file', audioBlob, 'mcpuff.webm')
      const response = await API.postData(
        ApiRoutes.dashboard.ai.transcribeAudio,
        formData
      )
      return response.data.transcription
    } catch (error) {
      console.error('Error getting audio transcript', error)
      toast.error('Try again.')
      return null
    }
  }

  @action
  addChatMessage = (message: string, role: 'user' | 'assistant'): void => {
    this.chatHistory = [...this.chatHistory, { message, role }]
  }

  @action
  initializeChat = (threadId: string): void => {
    this.threadId = threadId
  }

  @action
  sendPrompt = async (
    prompt: string,
    selectedText?: string
  ): Promise<string | { error: string }> => {
    try {
      let payload = {}

      if (!prompt) {
        // @todo create error class and handle it where it is called
        return ''
        // throw new Error('Prompt is required')
      }
      if (!this.threadId && !selectedText)
        throw new Error(
          'selected text is required for initial chat thread creation'
        )
      this.addChatMessage(prompt, 'user')
      if (this.threadId) {
        payload = { message: prompt, thread_id: this.threadId }
      } else {
        payload = { message: prompt, selected_text: selectedText }
      }

      const response = await API.postData(ApiRoutes.dashboard.ai.chat, payload)
      this.addChatMessage(response.data.ai_message, 'assistant')
      this.initializeChat(response.data.thread_id)
      return response.data.ai_message
    } catch (error) {
      console.log(error)
      return { error: 'Error sending prompt' }
    }
  }

  @action
  resetThreadId = (): void => {
    this.threadId = ''
    this.chatHistory = []
  }

  @action
  resetStore = (): void => {
    this.threadId = ''
    this.promptsTemplates = []
    this.chatHistory = []
  }
}

export default new AIStore()
