import type {
  CandidateAdsDetailsSchema,
  CandidateAdsListSchema
} from 'src/types/APITypes'
import Salary, { ISalaryModel } from './Salary'
import { getLocationsWithFieldId } from 'src/utils/helpers/locations'

type Locations = {
  fieldId: string
  location: number
  subLocation: number | null
}

export type ICandidateAdsDetailsModel = {
  id: CandidateAdsDetailsSchema['id']
  title: CandidateAdsDetailsSchema['title']
  description: CandidateAdsDetailsSchema['description']
  numberOfViews: CandidateAdsDetailsSchema['number_views']
  candidate: CandidateAdsDetailsSchema['candidate']
  consultant: CandidateAdsDetailsSchema['consultant']
  locations: Array<Locations>
  locationsList: CandidateAdsDetailsSchema['locations']
  worktypes: CandidateAdsDetailsSchema['worktypes']
  skills: CandidateAdsDetailsSchema['skills']
  expiredAt: CandidateAdsDetailsSchema['expired_at']
  enquiresCount: CandidateAdsDetailsSchema['enquires_count']
  isExpired: boolean
  salary?: ISalaryModel
  summary: CandidateAdsDetailsSchema['summary']
  fullName: string // computed property
  status: CandidateAdsListSchema['status']
  is_featured: CandidateAdsListSchema['is_agency_featured']
  candidate_ad_featured_count: CandidateAdsDetailsSchema['agency_featured_count']
}

class CandidateAdsDetailsModel implements ICandidateAdsDetailsModel {
  id: ICandidateAdsDetailsModel['id']
  title: ICandidateAdsDetailsModel['title']
  description: ICandidateAdsDetailsModel['description']
  numberOfViews: ICandidateAdsDetailsModel['numberOfViews']
  candidate: ICandidateAdsDetailsModel['candidate']
  consultant: ICandidateAdsDetailsModel['consultant']
  locations: ICandidateAdsDetailsModel['locations']
  locationsList: ICandidateAdsDetailsModel['locationsList']
  worktypes: ICandidateAdsDetailsModel['worktypes']
  skills: ICandidateAdsDetailsModel['skills']
  expiredAt: ICandidateAdsDetailsModel['expiredAt']
  enquiresCount: ICandidateAdsDetailsModel['enquiresCount']
  isExpired: ICandidateAdsDetailsModel['isExpired']
  salary?: ISalaryModel
  summary: ICandidateAdsDetailsModel['summary']
  status: ICandidateAdsDetailsModel['status']
  is_featured: ICandidateAdsDetailsModel['is_featured']
  candidate_ad_featured_count: ICandidateAdsDetailsModel['candidate_ad_featured_count']

  constructor(data: CandidateAdsDetailsSchema) {
    // @ts-ignore TODO: need to fix this type mismatch
    const locations = getLocationsWithFieldId(data.locations)
    this.status = data.status
    this.id = data.id
    this.title = data.title
    this.description = data.description
    this.numberOfViews = data.number_views
    this.candidate = data.candidate || {
      id: '',
      first_name: '',
      last_name: '',
      email: ''
    }
    this.consultant = data.consultant
    this.locations = locations
    this.locationsList = data.locations
    this.worktypes = data.worktypes
    this.skills = data.skills
    this.expiredAt = data.expired_at
    this.enquiresCount = data.enquires_count
    this.isExpired =
      this.status === 'draft' ? false : new Date(data.expired_at) < new Date()
    this.salary = data.salary ? new Salary(data.salary) : undefined
    this.summary = data.summary
    this.is_featured = data.is_agency_featured
    this.candidate_ad_featured_count = data.agency_featured_count
  }

  get fullName(): string {
    if (!this.consultant?.first_name && !this.consultant?.last_name) {
      return '-'
    }

    return (
      `${this.consultant.first_name} ${this.consultant.last_name}`.trim() || '-'
    )
  }
}

export default CandidateAdsDetailsModel
