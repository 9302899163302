/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { action, observable, computed, runInAction } from 'mobx'
import WebFont from 'webfontloader'
import * as axios from 'axios'
import SocializeStore from './SocializeStore'
import { Logo } from '../models/Agency'
import Image from '../models/Image'
import Logger from '../utils/Logger'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import UtilsStore from './UtilsStore'
import credentials from '../constants/credentials'
import RootStore from './RootStore'
import hexToRgba from 'hex-to-rgba'
import { toast } from 'react-toastify'

export class CanvasCardStore {
  fontHistory = {}
  @observable.ref framesLogo = []
  @observable.ref framesBG = []
  @observable.ref backgrounds = []
  @observable.ref backgroundsVideoThumbs = []
  @observable.ref title = {}
  @observable.ref additionalText = {}
  @observable.ref description = {}
  @observable.ref mainImage = {}
  @observable.ref mainWideImage = {}
  @observable.ref additionalImage = {}
  @observable.ref fonts = []

  @observable agencyImageIsUsed = true
  @observable consultantImageIsUsed = true
  @observable customBackground = null
  @observable starRating = false
  @observable torpecVerified = false

  @observable descriptionIsUsed = false
  @observable titleIsUsed = true

  @observable titleFontSize = 26
  @observable descriptionFontSize = 26
  @observable additionalFontSize = 26
  @observable editedCanvasType = ''
  @observable isOpenEditedCanvasModal = false
  @observable isEditedUpdated = false
  @observable isUsedRecruiterTopRecBar = true
  @observable isUsedCompanyTopRecBar = false

  @observable titleSelectedFont
  @observable descriptionSelectedFont
  @observable additionalSelectedFont
  @observable titleTextColor = null
  @observable descriptionTextColor = null
  @observable additionalTextColor = null
  @observable titleIsBold = true
  @observable descriptionIsBold = false
  @observable additionalIsBold = false
  @observable titleIsItalic = false
  @observable descriptionIsItalic = false
  @observable additionalIsItalic = false

  @observable isShowTestimonialLoader = false
  @observable textColor = null
  @observable playIconColor = '#ffffff'
  @observable tintColor = null

  @observable blurValue = 0
  @observable brightnessValue = 0
  @observable tintLocation = 100
  @observable tintAngle = 0

  @observable selectedBackgroundId
  @observable selectedGifFrame = 0
  @observable isOverwriteBackground = true
  @observable gifFramesCanvas = []
  @observable canvasImageGif = null
  @observable canvasImageOriginalGif = null
  @observable canvasImageSettings = null
  @observable isGIFRenderingOnScreen = false
  @observable selectedFont
  @observable selectedFontAlignment = 'center'
  @observable titleSelectedFontAlignment = 'center'
  @observable descriptionSelectedFontAlignment = 'center'
  @observable additionalSelectedFontAlignment = 'center'
  @observable siteFont = 'Roboto'
  @observable canvasImage = {}
  @observable canvasImageSaved = {}
  @observable opacity = 0
  @observable tintColorData = {}
  @observable testimonialSelectedLogo = 'profile_photo'
  @observable selectedLogo = 'square_logo'
  @observable selectedTestimonialBackgroundId = 0

  @observable.ref testimonialVerifiedBackgrounds = []
  @observable.ref testimonialBackgrounds = []
  @observable.ref fontAlignment = [
    {
      label: 'Left',
      value: 'left'
    },
    {
      label: 'Right',
      value: 'right'
    },
    {
      label: 'Center',
      value: 'center'
    },
    {
      label: 'Justify',
      value: 'justify'
    }
  ]
  @observable isTestimonials = false

  @action.bound
  async getGenerativeAISettings() {
    try {
      const response = await API.getData(
        ApiRoutes.socializeWidget.getAvailableAIBudget
      )
      if (response.status !== 200) return 0
      return response.data.available_budget
    } catch (error) {
      console.log('error happened')
      Logger.error(error)
      return 0
    }
  }

  @action.bound
  async generateDescriptionAndHashtags(requestParams) {
    try {
      const { postType } = requestParams
      let params = {}
      console.log('requestParams', requestParams)
      if (postType === 'job' && typeof requestParams.jobID !== 'undefined') {
        params = {
          obj_type: 'job',
          job: requestParams.jobID
        }
      } else if (postType === 'job_list') {
        params = {
          obj_type: 'job_list',
          locations: requestParams.locations,
          categories: requestParams.categories,
          search: requestParams.search
        }
      } else if (
        postType === 'testimonial' &&
        typeof requestParams.testimonialID === 'number'
      ) {
        params = {
          obj_type: 'testimonial',
          testimonial: requestParams.testimonialID
        }
      } else if (postType === 'profile') {
        params = {
          obj_type: 'profile'
        }
      } else if (
        postType === 'candidate_ad' &&
        typeof requestParams.candidateAdId !== 'undefined'
      ) {
        params = {
          obj_type: 'candidate_ad_socialize',
          candidate_ad: requestParams.candidateAdId
        }
      } else {
        toast.error('Something went wrong, try again later')
        throw new Error('Request Params are not valid')
      }
      const response = await API.postData(
        ApiRoutes.socializeWidget.generateAI,
        params
      )
      if (!response.data) throw response
      return {
        message: response.data?.message,
        hashtags: response.data?.hashtags,
        availableBudget: response.data?.available_budget,
        price: response.data?.price
      }
    } catch (error) {
      console.log('error while generating description and hashtags')
      Logger.error(error)
      if (error?.response?.status === 503) {
        toast.error(
          // @ts-ignore type error objects
          error?.response?.data?.detail || 'Error with connection to AI service'
        )
      } else if (error?.data?.error) {
        toast.error(error.data.error)
      } else {
        toast.error('Something went wrong, Please Try Again.')
      }
      return null
    }
  }

  @computed
  get selectedBackground() {
    if (SocializeStore.isCustomImage) {
      return new Logo({
        id: SocializeStore.image.value,
        image: SocializeStore.image.fileData.path
      })
    }
    const allBackgrounds = this.backgroundsVideoThumbs.concat(this.backgrounds)
    return allBackgrounds.find((item) => item.id === this.selectedBackgroundId)
  }

  @computed
  get selectedTestimonialBackground() {
    return this.testimonialBackgrounds.find(
      (item) => item.id === this.selectedTestimonialBackgroundId
    )
  }

  @computed
  get selectedTestimonialVerifiedBackground() {
    return this.testimonialVerifiedBackgrounds.find(
      (item) => item.id === this.selectedTestimonialBackgroundId
    )
  }

  getBackgroundsList = async (videoThumbs = []) => {
    try {
      // if (this.backgrounds.length < 1) {
      const url = this.isTestimonials
        ? ApiRoutes.publicPosts.testimonialsBackground
        : ApiRoutes.publicPosts.otherBackgrounds
      const response = await API.getData(url)
      const backgroundsList = Array.isArray(response.data)
        ? response.data.map((item) => new Image(item))
        : []
      runInAction(() => {
        this.backgrounds = backgroundsList
      })
      // }

      let selectedId = 0
      const backgroundsVideoThumbList = []
      if (videoThumbs && videoThumbs.length > 0) {
        for (let i = 0; i < videoThumbs.length; i++) {
          const data = {
            id: videoThumbs[i].video_code,
            image: videoThumbs[i].image,
            thumb: videoThumbs[i].thumb_small,
            thumb_large: videoThumbs[i].thumb_large,
            isVideo: true
          }
          backgroundsVideoThumbList.push(data)
          if (this.backgrounds.length) selectedId += 1
        }
      }
      runInAction(() => {
        this.backgroundsVideoThumbs = backgroundsVideoThumbList
      })

      const allBackgrounds = this.backgroundsVideoThumbs.concat(
        this.backgrounds
      )
      console.log('CanvasCardStore -> getBackgroundList')
      console.log('selectedId', selectedId)
      console.log('allBackgrounds', allBackgrounds)
      console.log(
        'selectedBackgroundId',
        allBackgrounds.length ? allBackgrounds[selectedId].id : null
      )
      this.setBackground(
        allBackgrounds.length ? allBackgrounds[selectedId].id : null,
        true
      )
    } catch (error) {
      Logger.error(error)
    }
  }

  loadFontList = async () => {
    if (this.fonts.length) return
    try {
      const response = await fetch(
        `${ApiRoutes.googlefonts}?key=${credentials.google.apiKey}&sort=popularity`
      )
      if (response && !response.error) {
        const data = await response.json()
        if (data) {
          runInAction(() => {
            const items = data.items.sort((a, b) =>
              a.family.localeCompare(b.family)
            )
            this.fonts = items.map((font) => ({
              label: font.family,
              value: font.family
            }))
          })
        }
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  selectFont = (name) => {
    if (name) {
      const selectedFont = this.fonts.filter((item) => item.value === name)[0]

      if (this.fontHistory[name]) {
        runInAction(() => {
          this.selectedFont = selectedFont
        })
      } else {
        WebFont.load({
          google: {
            families: [name]
          },
          classes: false,
          fontloading: (familyName, fvd) => {},
          fontactive: (familyName, fvd) => {
            this.fontHistory[name] = true
            runInAction(() => {
              this.selectedFont = selectedFont
            })
          },
          fontinactive: (familyName, fvd) => {}
        })
      }
    }
  }

  @action
  selectEditedFont = (name, type) => {
    if (name) {
      const selectedFont = this.fonts.filter((item) => item.value === name)[0]

      if (this.fontHistory[name]) {
        runInAction(() => {
          this[`${type}SelectedFont`] = selectedFont
        })
      } else {
        WebFont.load({
          google: {
            families: [name]
          },
          classes: false,
          fontloading: (familyName, fvd) => {},
          fontactive: (familyName, fvd) => {
            this.fontHistory[name] = true
            runInAction(() => {
              this[`${type}SelectedFont`] = selectedFont
            })
          },
          fontinactive: (familyName, fvd) => {}
        })
      }
    }
  }

  @action.bound
  toggleEditedCanvasModal() {
    this.isOpenEditedCanvasModal = !this.isOpenEditedCanvasModal
  }

  @action.bound
  setGifFrame(id) {
    this.selectedGifFrame = id
  }

  @action.bound
  setTestimonialBackgroundSettings = async (settings) => {
    console.log('setTestimonialBackgroundSettings', this)
    console.log(this.canvasImageSettings)
    // updateTestimonialBackgroundSettings
    await RootStore.updateTestimonialBackgroundSettings(
      this.selectedBackgroundId,
      settings
    )
  }

  @action.bound
  setBackground = async (backgroundId, isFetchData) => {
    // if (this.isTestimonials) {
    //   this.setCustomBackgroundSettings({ settings_data: null })
    //   this.customBackground = { settings_data: null }
    //   this.framesBG = []
    //   this.selectedBackgroundId = backgroundId
    //   return
    // }

    if (backgroundId) {
      if (isFetchData) {
        const response = await RootStore.fetchCustomBackground(backgroundId)
        console.log('CanvasCardStore -> SetBackground -> response', response)
        if (response) {
          runInAction(() => {
            this.reset()
            this.setCustomBackgroundSettings(response)
            this.customBackground = response
            this.framesBG = []
            this.selectedBackgroundId = backgroundId
          })
        } else {
          this.setCustomBackgroundSettings({ settings_data: null })
          this.customBackground = { settings_data: null }
          this.framesBG = []
          this.selectedBackgroundId = backgroundId
        }
      } else {
        runInAction(() => {
          this.customBackground = { settings_data: null }
          this.framesBG = []
          this.selectedBackgroundId = backgroundId
        })
      }
    } else {
      runInAction(() => {
        this.setCustomBackgroundSettings({ settings_data: null })
        this.customBackground = { settings_data: null }
        this.framesBG = []
      })
    }
  }

  @action.bound
  setCustomBackgroundSettings(customBackground) {
    // this.textColor = customBackground.settings_data && customBackground.settings_data.common ? customBackground.settings_data.common.color : RootStore.agency.branding.textColorSocialize
    // this.tintColor = customBackground.settings_data && customBackground.settings_data.common ? customBackground.settings_data.common.tint : RootStore.agency.branding.primaryColorSocialize
    const textColorFromBranding = RootStore.agency.branding.useAdvancedOptions
      ? RootStore.agency.branding.textColorSocialize ||
        RootStore.agency.branding.textColor
      : RootStore.agency.branding.textColor
    this.textColor =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.color
        : textColorFromBranding

    const tintColorFromBranding = RootStore.agency.branding.useAdvancedOptions
      ? RootStore.agency.branding.primaryColorSocialize ||
        RootStore.agency.branding.primaryColor
      : RootStore.agency.branding.primaryColor
    this.tintColor =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.tint
        : tintColorFromBranding

    let color = hexToRgba(
      customBackground.settings_data &&
        customBackground.settings_data.common &&
        customBackground.settings_data.common.tint
        ? customBackground.settings_data.common.tint
        : tintColorFromBranding
    )

    if (color) {
      color = color.replace('rgba(', '').replace(')', '')
      color = color.split(', ').slice(0, 3)
      this.tintColorData = {
        rgb: { r: color[0], g: color[1], b: color[2] }
      }
      // canvasCardStore.setData('tintColorData', {
      //   rgb: { r: color[0], g: color[1], b: color[2] }
      // })
    }
    this.blurValue =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.blur
        : 0
    this.brightnessValue =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.brightness
        : 0
    this.tintLocation =
      customBackground.settings_data &&
      customBackground.settings_data.common &&
      customBackground.settings_data.common.tintLocation !== undefined
        ? customBackground.settings_data.common.tintLocation
        : 100
    this.tintAngle =
      customBackground.settings_data &&
      customBackground.settings_data.common &&
      customBackground.settings_data.common.tintAngle !== undefined
        ? customBackground.settings_data.common.tintAngle
        : 0
    this.opacity =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.opacity
        : 0
    this.titleIsUsed =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.showTitle
        : true
    if (!this.isTestimonials) {
      this.descriptionIsUsed =
        customBackground.settings_data && customBackground.settings_data.common
          ? customBackground.settings_data.common.showDescription
          : false
    } else {
      this.descriptionIsUsed = true
    }
    this.agencyImageIsUsed =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.showLogo
        : true
    this.selectedLogo =
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.whichLogo
        : 'square_logo'
    this.isUsedRecruiterTopRecBar = RootStore.agency.showConsultantRating
      ? customBackground.settings_data &&
        customBackground.settings_data.common &&
        customBackground.settings_data.common.showTopRecBar !== undefined
        ? customBackground.settings_data.common.showTopRecBar
        : true
      : false
    this.isUsedCompanyTopRecBar =
      customBackground.settings_data &&
      customBackground.settings_data.common &&
      customBackground.settings_data.common.showCompanyTopRecBar !== undefined
        ? customBackground.settings_data.common.showCompanyTopRecBar
        : false
    this.selectedGifFrame =
      customBackground.settings_data &&
      customBackground.settings_data.common &&
      customBackground.settings_data.common.selectedFrameIndex !== undefined
        ? customBackground.settings_data.common.selectedFrameIndex
        : 0

    this.titleTextColor =
      customBackground.settings_data && customBackground.settings_data.title
        ? customBackground.settings_data.title.color
        : null
    this.descriptionTextColor =
      customBackground.settings_data &&
      customBackground.settings_data.description
        ? customBackground.settings_data.description.color
        : null
    this.additionalTextColor =
      customBackground.settings_data &&
      customBackground.settings_data.additional
        ? customBackground.settings_data.additional.color
        : null

    this.titleIsBold =
      customBackground.settings_data && customBackground.settings_data.title
        ? customBackground.settings_data.title.bold
        : false
    this.descriptionIsBold =
      customBackground.settings_data &&
      customBackground.settings_data.description
        ? customBackground.settings_data.description.bold
        : false
    this.additionalIsBold =
      customBackground.settings_data &&
      customBackground.settings_data.additional
        ? customBackground.settings_data.additional.bold
        : false

    this.titleIsItalic =
      customBackground.settings_data && customBackground.settings_data.title
        ? customBackground.settings_data.title.italic
        : false
    this.descriptionIsItalic =
      customBackground.settings_data &&
      customBackground.settings_data.description
        ? customBackground.settings_data.description.italic
        : false
    this.additionalIsItalic =
      customBackground.settings_data &&
      customBackground.settings_data.additional
        ? customBackground.settings_data.additional.italic
        : false

    this.titleFontSize =
      customBackground.settings_data &&
      customBackground.settings_data.title &&
      customBackground.settings_data.title.fontSize
        ? customBackground.settings_data.title.fontSize
        : 26
    this.descriptionFontSize =
      customBackground.settings_data &&
      customBackground.settings_data.description &&
      customBackground.settings_data.description.fontSize
        ? customBackground.settings_data.description.fontSize
        : 26
    this.additionalFontSize =
      customBackground.settings_data &&
      customBackground.settings_data.additional &&
      customBackground.settings_data.additional.fontSize
        ? customBackground.settings_data.additional.fontSize
        : 26

    // Set Font
    this.selectFont(
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.font
        : RootStore.agency.branding.socializeFont
    )
    this.selectEditedFont(
      customBackground.settings_data && customBackground.settings_data.title
        ? customBackground.settings_data.title.font
        : RootStore.agency.branding.socializeFont,
      'title'
    )
    this.selectEditedFont(
      customBackground.settings_data &&
        customBackground.settings_data.description
        ? customBackground.settings_data.description.font
        : RootStore.agency.branding.socializeFont,
      'description'
    )
    this.selectEditedFont(
      customBackground.settings_data &&
        customBackground.settings_data.additional
        ? customBackground.settings_data.additional.font
        : RootStore.agency.branding.socializeFont,
      'additional'
    )

    // Set Font Alignment
    this.selectedFontAlignment =
      customBackground.settings_data &&
      customBackground.settings_data.common &&
      customBackground.settings_data.common.fontAlignment
        ? customBackground.settings_data.common.fontAlignment
        : 'center'
    this.titleSelectedFontAlignment =
      customBackground.settings_data &&
      customBackground.settings_data.title &&
      customBackground.settings_data.title.fontAlignment
        ? customBackground.settings_data.title.fontAlignment
        : this.selectedFontAlignment
    this.descriptionSelectedFontAlignment =
      customBackground.settings_data &&
      customBackground.settings_data.description &&
      customBackground.settings_data.description.fontAlignment
        ? customBackground.settings_data.description.fontAlignment
        : this.selectedFontAlignment
    this.additionalSelectedFontAlignment =
      customBackground.settings_data &&
      customBackground.settings_data.additional &&
      customBackground.settings_data.additional.fontAlignment
        ? customBackground.settings_data.additional.fontAlignment
        : this.selectedFontAlignment
  }

  @action.bound
  setTestimonialBackground(backgroundId) {
    this.selectedTestimonialBackgroundId = backgroundId
    this.isShowTestimonialLoader = true
    setTimeout(() => {
      this.setData('isShowTestimonialLoader', false)
    }, 500)
  }

  @action.bound
  toggleLogo() {
    this.framesLogo = []
    this.agencyImageIsUsed = !this.agencyImageIsUsed
  }

  toggle = (key) => () => {
    this.setData(key, !this[key])
  }

  @action.bound
  handleRadioCheck(e) {
    this.testimonialSelectedLogo = e.target.value
  }

  @action.bound
  handleLogoRadioCheck(e) {
    this.selectedLogo = e.target.value
  }

  @action.bound
  toggleProfilePhoto() {
    this.consultantImageIsUsed = !this.consultantImageIsUsed
  }

  @action.bound
  toggleDescription() {
    this.descriptionIsUsed = !this.descriptionIsUsed
  }

  @action.bound
  toggleTitle() {
    this.titleIsUsed = !this.titleIsUsed
  }

  @action.bound
  toggleRecruiterTopRecBar() {
    if (!this.isUsedRecruiterTopRecBar) {
      this.isUsedCompanyTopRecBar = false
    }
    this.isUsedRecruiterTopRecBar = !this.isUsedRecruiterTopRecBar
  }

  @action.bound
  toggleCompanyTopRecBar() {
    if (!this.isUsedCompanyTopRecBar) {
      this.isUsedRecruiterTopRecBar = false
    }
    this.isUsedCompanyTopRecBar = !this.isUsedCompanyTopRecBar
  }

  @action.bound
  async disableAllBackgroundOptions() {
    this.titleIsUsed = false
    this.descriptionIsUsed = false
    this.isUsedRecruiterTopRecBar = false
    this.isUsedCompanyTopRecBar = false
    this.agencyImageIsUsed = false
  }

  @action.bound
  async reset() {
    console.log('canvascardstore -> reset')
    this.title = {}
    this.additionalText = {}
    this.description = {}
    this.mainImage = {}
    this.mainWideImage = {}
    this.additionalImage = {}
    this.agencyImageIsUsed = true
    if (!this.isTestimonials) this.descriptionIsUsed = false
    this.isUsedRecruiterTopRecBar = true
    this.isUsedCompanyTopRecBar = false
    this.textColor = null
    this.gifFramesCanvas = []
    this.canvasImageGif = null
    this.canvasImageOriginalGif = null
    this.canvasImageSettings = null
    this.selectedGifFrame = 0
    this.isOverwriteBackground = true
    this.playIconColor = '#ffffff'
    this.tintColor = null
    this.testimonialSelectedLogo = 'profile_photo'
    this.selectedLogo = 'square_logo'
    this.editedCanvasType = ''
    this.blurValue = 0
    this.brightnessValue = 0
    this.tintLocation = 100
    this.tintAngle = 0
    this.titleIsUsed = true
    this.selectedFont = null
    this.previousFont = null
    this.titleSelectedFont = null
    this.descriptionSelectedFont = null
    this.isEditedUpdated = false
    this.additionalSelectedFont = null
    this.selectedFontAlignment = null
    this.titleSelectedFontAlignment = null
    this.descriptionSelectedFontAlignment = null
    this.additionalSelectedFontAlignment = null
    this.titleTextColor = null
    this.descriptionTextColor = null
    this.additionalTextColor = null
    this.titleIsBold = true
    this.descriptionIsBold = false
    this.additionalIsBold = false
    this.titleIsItalic = false
    this.descriptionIsItalic = false
    this.additionalIsItalic = false
    if (this.backgrounds.length) {
      await this.setBackground(this.backgrounds[0].id, false)
    } else if (this.backgroundsVideoThumbs.length) {
      await this.setBackground(this.backgroundsVideoThumbs[0].id, false)
    }
    if (this.testimonialBackgrounds.length) {
      this.setTestimonialBackground(this.testimonialBackgrounds[0].id)
    }
  }

  @action.bound
  setData(name, data) {
    this[name] = data
  }
}

export default new CanvasCardStore()
